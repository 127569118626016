<template>
  <div class="church-list template-1">
    <header class="page-header container">
      <h1>{{ translations.tcGideonCardFacilitiesList }}</h1>
    </header>
    <security-banner v-if="!allowPage" :i18n="translations.components"></security-banner>
    <div v-if="allowPage">
      <camp-selector
        @camp_changed="page_load($event)"
        :tooltip="translations.tcTooltip"
        :i18n="translations.components"
      ></camp-selector>
      <data-table
        :fields="dataTableFields"
        :items="translatedFacilities"
        :toggleMe="true"
        :listType="list_type"
        :hiddenItems="hiddenItems"
        :addItems="addItems"
        :includeAddButton="iCanSeeAny(secured_gideoncard_facility_add_controls)"
        :searchTerm="`gideonCardTerm`"
        :csvUrl="csvUrl"
        :pdfUrl="pdfUrl"
        :reportName="reportName"
        @addPresenter="addPresenter"
        :i18n="translations.components"
      ></data-table>
      <footer class="page-footer"></footer>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import DataTable from '@/components/DataTable.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'gideoncard-facilities',
  mixins: [translationMixin],
  data() {
    return {
      addItems: {
        display: true,
        text: 'Add Facility',
        action: 'addPresenter',
      },
      dataTableFields: [
        { key: 'facility_name', label: '', sortable: true },
        { key: 'facility_type', label: '', sortable: true },
        { key: 'city', label: '', sortable: true },
        { key: 'displays', label: '', sortable: true },
        {
          key: 'activity_date',
          label: '',
          sortable: true,
          formatter: (value, key, item) => {
            if (value == null) {
              return ''
            }
            let visit_date = new Date(value)
            return visit_date.toISOString().slice(0, 10)
          },
        },
        { key: 'meeting_type', label: '', sortable: true },
      ],
      hiddenItems: {
        display: false,
      },
      list_type: 'facility',
      reportName: 'GideonCardFacilitiesByCamp',
      secured_gideoncard_facility_add_controls: {
        add_gideoncard_facility_button: 'ad5a97c5-ce8c-4388-8895-3396aaf99bd9',
      },
      translatedFacilities: [],
      translations: {},
    }
  },
  methods: {
    ...mapActions({
      getFacilities: 'card/getFacilities',
      getFacilitiesReportsUrls: 'card/getFacilitiesReportsUrls',
      loadHeldVisitsByCamp: 'churchMinistry/loadHeldVisitsByCamp',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    async page_load(evt) {
      try {
        if (!evt) {
          // execute only if evt is undefined
          await Promise.all([await this.getFacilities(), await this.getFacilitiesReportsUrls()]).then(() => {
            this.translateData()
          })
        }
      } catch (e) {
        console.error(e)
      } finally {
      }
    },
    addPresenter() {
      this.$router.push({ name: 'card_facility_add' })
    },
    loadTranslations() {
      this.addItems.text = this.translations.tcAddFacility
      this.loadDataTableFields()
    },
    loadDataTableFields() {
      this.dataTableFields = [
        { key: 'facility_name', label: this.translations.tcFacilityName, sortable: true },
        { key: 'facility_type', label: this.translations.tcFacilityType, sortable: true },
        { key: 'city', label: this.translations.tcCity, sortable: true },
        { key: 'displays', label: this.translations.tcDisplays, sortable: true },
        {
          key: 'activity_date',
          label: this.translations.tcActivityDate,
          sortable: true,
          formatter: (value, key, item) => {
            if (value == null) {
              return ''
            }
            let visit_date = new Date(value)
            return visit_date.toISOString().slice(0, 10)
          },
        },
        { key: 'meeting_type', label: this.translations.tcMeetingType, sortable: true },
      ]
    },
    translateData() {
      if (!this.facilities) return false

      this.translatedFacilities = this.facilities.map((facility) => {
        const lookupName = this.convertValForTranslation(facility.facility_type) || ''

        const translatedText = this.translations.commonText['placement-facility-types'].hasOwnProperty(lookupName)
          ? this.translations.commonText['placement-facility-types'][lookupName]
          : facility.facility_type

        return { ...facility, facility_type: translatedText }
      })
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([
        this.getViewTranslations(),
        this.getComponentTranslations('data-table', 'camp-select', 'security-banner'),
        this.getComponentTranslations('common.placement-facility-types'),
      ]).then((results) => {
        this.stripReadableText(results[2], 'text')
        const translatedText = {
          ...results[1],
          common: { ...this.translations.common },
        }
        this.$set(this.translations, 'components', translatedText)

        this.loadTranslations()
        this.page_load()
      })
    } catch (e) {
      console.error('Error in GideonCardFacilities.vue, created(), ', e)
    } finally {
      await this.setLoadingStatus(false)
    }
  },
  computed: {
    items() {
      return churches
        .filter((item) => {
          return item.list === this.camp
        })
        .map((el) => {
          return {
            'Church Name': el['Church Name'],
            City: el['City'],
            Denomination: el.miscellaneous.denomination,
            GideonRep: el.miscellaneous.GideonRep,
            'Camp Assigned': el.miscellaneous['assignment-number'],
            'Last Presentation': el.miscellaneous['last-presentation-date'],
          }
        })
    },
    ...mapGetters({
      csvUrl: 'card/facilityCSVUrl',
      facilities: 'card/facilities',
      held_visits_by_camp: 'churchMinistry/held_visits_by_camp',
      iCanSeeAny: 'user/iCanSeeAny',
      isInMenu: 'menu/isInMenu',
      pdfUrl: 'card/facilityPDFUrl',
      prefCulture: 'user/userPreferredCulture',
    }),
    allowPage() {
      return this.isInMenu(this.$route.path)
    },
  },
  components: {
    campSelector: CampSelect,
    dataTable: DataTable,
    SecurityBanner,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchList.scss';
</style>
